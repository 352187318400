body {
  background: inherit;
}

.preview-image:hover {
  background: linear-gradient(
    91.47deg,
    #3eba8d -10.34%,
    #6475b9 102.62%
  ) !important;
}

/* .forGradient:before,
.forGradient:after {
  content: "";
  position: absolute;
  width: 40%;
  height: 50%;
  opacity: 0.8;
  border-radius: 50%;
}
.forGradient:before {
  background: linear-gradient(108deg, #41579C, transparent);
  z-index: 2;
  bottom: -100px;
  left: -100px;
}
.forGradient:after {
  background: linear-gradient(142deg, transparent, #178570);
  z-index: 3;
  bottom: -100px;
  right: -100px;
} */

.forGradient {
  /* position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; */
  padding-bottom: 50px;
}
/* .forGradient::-webkit-scrollbar-track {
  display: 'none';
}
.forGradient::-webkit-scrollbar {
  display: 'none';
}
.forGradient::webkit-scrollbar-thumb {
  display: 'none';
} */

.forGradient:before {
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0%;

  background: /* newest syntax */ radial-gradient(
      circle at 2.7% 110%,
      #7181c5,
      rgba(0, 0, 255, 0) 40.71%
    ),
    radial-gradient(circle at 102.7% 110%, #20b992, rgba(0, 255, 0, 0) 40.71%);
}

.tabs-container {
  margin-top: 0.3em;
  margin-left: 1.5em;
  margin-right: 1.5em;
  font-size: 1.5rem;
  font-weight: bolder;
  padding: 1.5em 0em 1em 0em;
  color: white;
  /* transition: 0.3s ease-in-out; */
}
.tabBar {
  position: relative;
  display: inline-block;
  padding: 1.5em 3em 1em 2em;
  margin: -1px 10px -1px -7px;
  color: #20b992 !important;
}

.tabBarSecond {
  position: relative;
  display: inline-block;
  padding: 1.5em 3em 1em 3em;
  margin: -1px -7px;
  color: #20b992 !important;
}

.tabBar::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: -5px;
  left: 0;
  z-index: 2;
  border: 2px solid white;
  border-radius: 25px 25px 0 0;
  border-bottom-right-radius: -1rem;
  border-bottom-color: #251f57;
  transform: perspective(10px) rotateX(2deg);
  transform-origin: bottom left;
}

.tabBarSecond::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: -5px;
  left: 0;
  z-index: 2;
  border: 2px solid white;
  border-radius: 25px 25px 0 0;
  border-bottom-color: #251f57;
  transform: perspective(10px) rotateX(2deg);
  transform-origin: bottom;
}
/* .tabBar::after {
  content: "";
  padding: 20px;
  border-right: 2px solid red;
} */
/* .tabBarSecond {
  padding: 20px;
  border: 2px solid White;
  border-radius: 10px;
  color: white;
} */

.loader {
  overflow: hidden;
  height: 100vh;
}

.hexagon {
  max-width: 280px;
  max-height: 280px;
  -webkit-mask-image: url("./assets/images/hexagon.png");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative !important;
}
.hexagon1 {
  max-width: 200px;
  max-height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-mask-image: url("./assets/images/hexagon.png");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
}

.hexagonn {
  position: relative;
  width: 230px; /* Adjust the size as needed */
  height: 200px; /* Adjust the size as needed */
  clip-path: polygon(25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%);
  background-image: linear-gradient(91.47deg, #3eba8d -10.34%, #6475b9 102.62%);
  display: flex;
  transform: rotate(90deg);
  justify-content: center;
  align-items: center;
}
.hexagonn1 {
  position: relative;
  width: 180px; /* Adjust the size as needed */
  height: 150px; /* Adjust the size as needed */
  clip-path: polygon(25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%);
  background-image: linear-gradient(91.47deg, #3eba8d -10.34%, #6475b9 102.62%);
  display: flex;
  transform: rotate(90deg);
  justify-content: center;
  align-items: center;
}
.hexagonn-inner {
  width: 95%;
  height: 95%;
  clip-path: polygon(25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.hexagonn-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hexagonn-box {
  transform: rotate(-90deg);
}

.hexBg {
  position: absolute;
  top: 0;
  background-image: url("./assets/images/hex3.png");
  height: 100vh;
  width: 100vw;
  background-size: 0%;
  background-repeat: no-repeat;
  /* transform-origin: center center; */
  background-position: center;
  transition: all 1s ease-in-out;
}

.hexBgAnimate {
  background-size: 130%;
}

.hex {
  position: absolute;
  top: 16%;
  left: 35%;
  scale: 0;
  animation: hexAnimation 1s infinite ease-in-out;
  animation-delay: 2s;
}
.hex2 {
  position: absolute;
  top: -50%;
  left: 5%;
  scale: 0;
  /* transform: rotate(60deg); */
  /* transform: translateX(-180px) translateY(-150px); */
  animation: hexAnimation2 1s infinite ease-in-out;
  /* animation-delay: 0.s; */
  transform-origin: center;
}
.hex2 {
  position: absolute;
  top: -50%;
  left: 5%;
  scale: 0;
  /* transform: rotate(60deg); */
  /* transform: translateX(-180px) translateY(-150px); */
  animation: hexAnimation2 1s infinite ease-in-out;
  /* animation-delay: 0.s; */
  transform-origin: center;
}

.cube {
  position: relative;
  height: 100vh;
  animation: cubeAnimation 1s infinite ease-in-out;
  animation-delay: 2s;
  transform-origin: center;
}

.top {
  position: absolute;
  left: -25.46%;
  top: -15.3%;
  animation: topAnimation 1.5s ease-in-out;
}
.left {
  position: absolute;
  left: -22.13%;
  top: 101.25%;
  animation: leftAnimation 1s ease-in-out;
  animation-delay: 0.5s;
}
.right {
  position: absolute;
  left: 104.92%;
  top: 90.74%;
  animation: rightAnimation 1.5s ease-in-out;
  animation-delay: 1s;
}
.topVisible {
  position: absolute;
  left: 46.43%;
  top: 42.98%;
  animation: myScale 0.5s infinite ease-in-out;
  animation-delay: 2s;
}
.leftVisible {
  position: absolute;
  left: 46.43%;
  top: 47.86%;
  animation: myScale 0.5s infinite ease-in-out;
  animation-delay: 2s;
}
.rightVisible {
  position: absolute;
  left: 49.75%;
  top: 44.5%;
  animation: myScale 0.5s infinite ease-in-out;
  animation-delay: 2s;
}

@keyframes cubeAnimation {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes leftAnimation {
  0% {
    left: -22.13%;
    top: 101.25%;
  }
  33% {
    left: 46.43%;
    top: 47.86%;
  }
  100% {
    left: 46.43%;
    top: 47.86%;
  }
}
@keyframes rightAnimation {
  0% {
    left: 104.92%;
    top: 90.74%;
  }
  33% {
    left: 49.75%;
    top: 44.5%;
  }
  100% {
    left: 49.75%;
    top: 44.5%;
  }
}
@keyframes topAnimation {
  0% {
    left: -25.46%;
    top: -15.3%;
  }
  33% {
    left: 46.43%;
    top: 42.98%;
  }
  100% {
    left: 46.43%;
    top: 42.98%;
  }
}

@keyframes myScale {
  0% {
    scale: 0;
    opacity: 0;
  }
  50% {
    scale: 1;
    opacity: 1;
  }
  100% {
    scale: 1.5;
    opacity: 0;
  }
}

@keyframes hexAnimation {
  0% {
    scale: 0;
    transform: rotate(0deg);
  }
  33% {
    scale: 0.8;
    transform: rotate(30deg);
  }
  70% {
    scale: 0.8;
    transform: rotate(60deg);
  }
  100% {
    scale: 0;
    transform: rotate(30deg);
  }
}
@keyframes hexAnimation2 {
  0% {
    scale: 0;
  }

  100% {
    scale: 2;
  }
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 3;
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
}

.overlay:hover {
  opacity: 1;
}
